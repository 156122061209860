import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import AppFooter from '../Components/AppFooter';
import { Button } from 'react-bootstrap';

export default function MeetTheTeam() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan = {"Med-O-Lark staffers make a diffrence!"}/>
        <AppMobNavigation />
        <main className='App-Main'>
            <Container>
                <Row>
                    <h1 className='Page-Name'>Meet The Team</h1>
                </Row>
                <Row>
                    <Container>
                        <Row>
                            <Col md={""}>
                                <h3>Scott and Dana Weinstein, Directors/Owners</h3>
                                <p>Scott and Dana Weinstein, Directors/Owners at Med-O-Lark since 2006, take their responsibility as mentors and role models for campers very seriously. Parenting their two children, Ellen and Danny, has heightened their awareness that young people need a safe and nurturing environment in which to grow. In the off-season, the Weinstein’s carefully select staff and adult artisans, both nationally and internationally, who share their vision and values. Scott completed his degree in finance and economics at New York University’s Stern School of Business while working at the New York Stock Exchange. Dana earned her BS in business administration and after graduation, entered the business world as well.</p>
                            </Col>
                            <Col  md={5}>
                                <Image style={{width: "100%"}} src="https://i0.wp.com/medolark.com/wp-content/uploads/2019/04/weinstein-family-directors-at-camp-medolark-1.jpg?w=400&ssl=1" />
                                <p>Scott and Dana Weinstein with their daughter, Ellen, and son, Danny.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{span: "", order: 2}}>
                                <h3>Brendan and Jessica Miller, Assistant Directors</h3>
                                <p>Brendan and Jessica Miller, Assistant Directors, are a warm and friendly couple who, along with their two boys Nathan and Dylan, are a part of the Med-O-Lark family. Brendan has earned a Bachelor of Science in Business Administration at SUNY Albany and worked at the New York Stock Exchange after graduation. Jessica has earned a Masters in Counseling from LIU CW Post and has worked in this field for six years.</p>
                                <p>Upon the birth of Nathan, the Miller’s decided to join the Med-O-Lark community to raise their family in a nurturing and wholesome environment. Both Brendan and Jessica were long time campers as children and strongly believe in the value of the camping experience. Both are considered by campers to be a great “Summer Mom and Dad.”.</p>
                            </Col>
                            <Col md={{span: 5, order: 1}}>
                                <Image style={{width: "100%"}} src="https://i0.wp.com/medolark.com/wp-content/uploads/2019/04/miller-family-directors-at-camp-medolark.jpg?w=400&ssl=1" />
                                <p>Brendan and Jessica Miller with sons Dylan and Nathan.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{span: "", order: 1}}>
                                <h3>Jody Moser, Assistant Director & Ethicist in Residence</h3>
                                <p>Calling Medolark home since 1999, Jody plans and manages the daily programs at camp, oversees the leadership development program, and is always available for any camper who needs an ear to listen or a friend to support them. Many campers drop by Jody’s office on a daily basis to check in, have a cup of tea, and talk about their camp experiences. Prior to assuming his current responsibilities as Assistant Director, he was a long-time camper and favorite counselor at Med-O-Lark where he learned intimately the benefits of being a part of such a broad and diverse camp family. As he has said at camp on numerous occasions, “Medolark is the quiet light of hope that lives at the very center of my heart.”</p>
                                <p>Jody received his Bachelor’s degree from Northwestern University where he studied philosophy and comparative religion, minoring in music. He holds master’s degrees from Northwestern and Harvard, and a PhD in Religious Studies grant from Northwestern University where he earned a Fulbright Grant for study in Rwanda. During the year, Jody is Assistant Professor of Humanities at Maine Maritime Academy, a college of engineering in Castine, Maine.</p>
                                <p>As an ethicist with strong roots in both religion and philosophy, and a classically trained musician with an avid dedication to inter- and cross-disciplinary work, Moser’s research interests include alterity, violence, responsibility, and the limits of phenomenology. A two-time National Champion in College Curling USA, when not at Camp, Jody can be found in his free time on the ice, singing in a choir, or cheering heartily for his other true love, college basketball.</p>
                            </Col>
                            <Col md={{span: 5, order: 2}}>
                                <Image style={{width: "100%"}} src="https://i0.wp.com/medolark.com/wp-content/uploads/2019/04/Jody-assitant-director-at-camp-medolark.jpg?w=400&ssl=1" />
                                <p>Jody Moser, Assistant Director and Ethicist in Residence.</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{span: "", order: 2}}>
                                <h3>Missy Vigue, Assistant Director and Registrar</h3>
                                <p>Missy Vigue, Assistant Director and Registrar, spends most of her time during the summer in the busy camp office, answering phones, speaking with parents, organizing camper travel and in the off-season, administering the extensive registration database. Missy was born in Atlanta and graduated from Vanderbilt University with a Bachelor’s degree in music performance. Missy completed her Master’s degree in orchestral conducting at the University of New Mexico before relocating to Washington, Maine, where she now lives with her husband Duane and sons, Brody and Logan. Laura Silvia,</p>
                            </Col>
                            <Col md={{span: 5, order: 1}}>
                                <Image style={{width: "100%"}} src="https://i0.wp.com/medolark.com/wp-content/uploads/2019/04/Missy-assitant-director-at-camp-medolark.jpg?w=400&ssl=1" />
                                <p>Missy and husband, Duane, and their sons, Brody and Logan.</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{span: "", order: 1}}>
                                <h3>Laura Silvia, Assistant Director</h3>
                                <p>Laura Silvia, Assistant Director, Laura was born and raised within walking distance of camp. Growing up, she became very knowledgeable of Med-O-Lark and its’ many programs and procedures. She joined the Med-O-Lark staff in the fall of 2006 to assist in the camp office, and manage a variety of administrative roles. She brings a warm, friendly disposition to the campers and staff. Through her well-established local relationships, she also brings the added dimension of enhancing Med-O-Lark’s good standing with the surrounding community. She is always available to be of service to anyone needing help with any questions or concerns.</p>
                            </Col>
                            <Col  md={{span: 5, order: 2}}>
                                <Image style={{width: "100%"}} src="https://i0.wp.com/medolark.com/wp-content/uploads/2019/04/Laura-assitant-director-at-camp-medolark.jpg?w=400&ssl=1" />
                                <p>Laura Silvia, Assistant Director.</p>
                            </Col>
                        </Row>


                        <Row>
                            <Col md={{span: "", order: 2}}>
                                <h3>Med-O-Lark Staff</h3>
                                <p>Med-O-Lark seeks individuals who are warm, caring, giving and nurturing. The average age of camp staff is 21. All cabin counselors are at least 18 years of age or older. Prior to the beginning of camp, Med-O-Lark staff attend a mandatory, intensive, ten-day training program. During this time, staff members receive training in camper safety and are familiarized with Med-O-Lark policies, procedures and values.</p>
                                <p>The length and scope of Med-O-Lark training program exceeds the training guidelines established by the American Camp Association. Med-O-Lark staff comes from many states and countries. Med-O-Lark prides itself on having a large number of artists and teachers. This mix of people with fresh ideas and unique perspectives forms a diverse community. Med-O-Lark counselors not only provide campers with support, guidance and instruction, but also with lasting friendship..</p>
                                <Button variant="outline-dark" onClick={() => window.location.href= "https://work.medolark.com"}>Join the Team</Button>
                            </Col>
                            <Col md={{span: 5, order: 1}}>
                                <Image style={{width: "100%"}} src="/unnamed (1).jpg" />
                                <p>Rocky, Becca, Bundy, James & Stewart. Some of Team Med-O-Lark 2023.</p>
                            </Col>
                        </Row>
                    </Container>       
                </Row>
              
            </Container>
        </main>   
        <AppFooter />   
   </div>
  );
}
import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import AppFooter from '../Components/AppFooter';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default function Activities() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan={"Med-O-Lark is your home away from home."} />
        <AppMobNavigation />
        <main className='App-Main'>
            <Container>
                <Row>
                    <h1 className='Page-Name'>Activities</h1>
                    <p>Med-O-Lark offers an all-elective program. Campers choose five activities/workshops (from over 50 choices) in each two-week session. In a four-week session, campers can experience up to ten activity/workshop periods.</p>
                    <p>There is a two-day drop-add period to change a class if desired. Activities are age and gender mixed, allowing each camper the opportunity to befriend many different community members, not just their cabinmates or age group.</p>
                    <p>No activities at Med-O-Lark are mandatory. Parents can request specific instruction in some fields and arrangements can be made to accommodate these requests.</p>
                </Row>  

                <Row>
                    <h1 className='Page-Name'>Activities Offered</h1>
                </Row>
                <Row>
                    <Col md={{span: 5, order: 2}}>
                        <Image style={{width: "100%"}} src="/watermarked_4bc34950d8c85c9ea6bd7d604be0df180f082305.jpg" />
                    </Col>
                    <Col md={{span: "", order: 1}}>
                        <h3>Circus Arts</h3>
                        <p>Med-O-Lark’s flying trapeze and circus program is available to all campers who want a taste of all that the Big Top has to offer. Med-O-Lark’s flying trapeze and circus program combines clowning, tumbling, juggling, trapeze, flying trapeze, Spanish web, tightrope and other acts which culminate in an end of camp extravaganza, for those who wish to participate.</p>
                        <p>Beginners through advanced circus performers will excel under the guidance of Med-O-Lark’s highly skilled circus teachers. In addition to being enormously fun, Med-O-Lark’s flying trapeze and circus program provide valuable skill development. For example, circus stunts combine physical conditioning, balance and dexterity; perfect for dancers and athletes alike. In addition, a successful aerial exhibition on the flying trapeze requires campers to trust others and work as a team.</p>
                    </Col>
                </Row>      

                <Row>
                    <Col md={{span: 5, order: 1}}>
                        <Image style={{width: "100%"}} src="/watermarked_4bc34950d8c85c9ea6bd7d604be0df180f082305.jpg" />
                    </Col>
                    <Col md={{span: "", order: 2}}>
                    <h3>Fine Arts</h3>
                        <p>All art activities are led by professional artisans or college students majoring in the arts. As a result, campers receive high-quality instruction. </p>
                        <p>Med-O-Lark is home to one of the largest and most all-encompassing Fine Arts programs of any camp. Camp life revolves around the Art Barn, located in the center of camp, and the many studios specifically designed and built for each art program surrounding it.</p>
                         <Button variant="outline-dark">See what we offer</Button>
                        
                    </Col>
                </Row>            
            </Container>
        </main>   
        <AppFooter />   
   </div>
  );
}
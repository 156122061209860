import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import AppFooter from '../Components/AppFooter';
import { Button } from 'react-bootstrap';

export default function Parents() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan={"Campers and their Parents are at the Heart of Med-O-Lark"} />
        <AppMobNavigation />
        <main className='App-Main'>
            <Container>
                <Row>
                    <h1 className='Page-Name'>Parent &amp; Carers</h1>
                    <p>This page is designed for Parents &amp; Carers. Here you will find all the information you should need</p>
                </Row>              
            </Container>
        </main>   
        <AppFooter />   
   </div>
  );
}
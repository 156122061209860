import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import AppFooter from '../Components/AppFooter';

export default function AboutUs() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan={ "Discover, grow, create, learn and shine!" } />
        <AppMobNavigation />
        <main className='App-Main'>
            <Container>
                <Row>
                    <h1 className='Page-Name'>About Us</h1>
                </Row>
                <Row>
                    <h3>Mission Statement</h3>
                    <p>Camp Med-O-Lark seeks to supplement the home and school in providing children a fun, safe environment for social, mental, cultural and physical development.  </p>
                </Row>
                <Row>
                    <h3>Philosophy</h3>
                    <p>Founded in 1967 as a non-competitive, non-traditional arts camp, Camp Med-O-Lark opened its arms to the creative, the curious and those free spirited.  Ours is a place where everyone is encouraged to embrace their individualism, and where their unique talents will be nurtured to grow.  Our philosophy incorporates the belief that exposure to the arts promotes positive self-expression in children, and that supporting artistic endeavors helps to build positive self esteem and a foundation for lifelong creativity.  Additionally, in order to provide a harmonious balance in children’s pursuits, a wide array of recreational and physical activity electives are offered to ensure stimulation of both body and mind.  Camp Med-O-Lark’s carefully constructed, diverse community draws from all walks of life and attracts campers from across the globe, as we feel this embodies the path for learning, growing and living most meaningfully.  </p>
                    <p>Situated on 50 acres within peaceful, natural surroundings in the Maine woods, Camp Med-O-Lark exemplifies the ideal setting for spiritual and educational uplift.   Our camp is located on a pleasant lakefront property that also houses a historic farm, creating a magical village which awakens the senses like few places can.  It’s an atmosphere filled with arts, activities, laughter and fun.  It’s an environment where friendships and memories form to last a lifetime.</p>
                </Row>
                <Row>
                    <h3>Structure</h3>
                    <p>Camp Med-O-Lark’s All-Elective activity schedule is designed for children to maximize choice and freedom. Campers choose five, one hour programs for a two-week session block.  There is a two day drop/add period for curriculum adjusting.  There are two, one hour free periods specifically designed each day for children to socialize, play, discover, relax, read, write, breathe, or most anything else they wish to spend time doing. There is a trip day and lazy day once weekly.</p>
                </Row>

                <Row>
                    <h3>Camp History</h3>
                   <p>Camp Med-O-Lark was founded in 1945 on the Southwest quadrant of Washington Lake in Washington, Maine.  It began its’ life as a sister camp to Medomak Camp, an all-boys camp located on the Northeast quadrant of Washington Lake. Med-O-Lark was organized by a man named Mr. Howard C. Hoople, an Associate Director of Mr. Frank Poland, the Founder and Director of Medomak Camp.*</p>
                   <p>A connection to the earliest private camp in America exists between these three institutions.  Medomak’s Mr. Poland was moved to action by an incredible and inspirational story he read in McClure’s magazine, (the old-time equivalent of today’s Field and Stream magazine.)**  The story, ‘A Boys Republic’,*** was written in 1893 by Alfred Balch, brother of Ernest Balch,**** who founded Camp Chocorua on an island in Big Asquam Lake, at the foothill of Mount Chocorua in New Hampshire. It elegantly described in detail the special world where young boys spent their summers learning and growing.   Camp Chocorua became the first private overnight camp in America with it’s birth in 1881.  Mr. Poland made his vision a reality in 1904 on Washington Lake, Maine.</p>
                   <p>Camp Med-O-Lark pivoted in 1967 to become the Co-ed Arts community it is today. Under the Direction of Mr. Jay Stager, Med-O-Lark instituted dynamic changes in philosophy, structure, curriculum and composition that appealed to the underserved demographic of Creative, Non-Competitive, Curious, Individualistic, Diverse family of Free-Spirited children.  These characteristics have been the hallmark and values of Camp Med-O-Lark ever since. </p>
                   <p>Scott and Dana Weinstein assumed Direction of Camp Med-O-Lark in the summer of 2006. During their tenure, the scope of activities has grown to compliment the robust Art programming Camp Med-O-Lark features.  Assistant Direction from Brendan and Jessica Miller, Laura Silvia, Missy Vigue and Jody Moser have been in place as long. </p>
                   <p>* Arthur Walworth, “The Medomak Way”, (Lancaster, New Hampshire: The Bisbee Press, 1953), 104</p>
                   <p>** Ibid, 13</p>
                   <p>*** Alfred Balch,  “A Boy’s Republic”, ( McClure’s 1893) </p>
                   <p>**** Ernest Berkley Balch, “The First Summer Camp”. (<a href="balchipedia.wikidot.com">balchipedia.wikidot.com</a>)</p>
                </Row>
            </Container>
        </main>   
        <AppFooter />   
   </div>
  );
}
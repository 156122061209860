import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import AppFooter from '../Components/AppFooter';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

export default function RatesAndDates() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan = {"Join us for 2024!"} />
        <AppMobNavigation />
        <main className='App-Main'>
            <Container>
                <Row>
                    <h1 className='Page-Name'>2024 Camp Session Dates</h1>
                </Row>
                <Row>
                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>First Session (4 Weeks)</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">$7,800</Card.Subtitle>
                            <Card.Text>
                                Friday, June 28th – Wednesday, July 24th.
                            </Card.Text>
                            <Card.Text><Button>Register Now</Button></Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Second Session: 3 Weeks</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">$6,000</Card.Subtitle>
                            <Card.Text>
                                Friday, July 26th – Wednesday, August 14th.
                            </Card.Text>
                            <Card.Text><Button>Register Now</Button></Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>2C Session: 2 Weeks</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">$4,700</Card.Subtitle>
                            <Card.Text>
                                Friday, July 26th – Thursday, August 8th.
                            </Card.Text>
                            <Card.Text><Button>Register Now</Button></Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Six Week Session</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">$11,300</Card.Subtitle>
                            <Card.Text>
                                Friday, June 28th – Thursday, August 8th.
                            </Card.Text>
                            <Card.Text><Button>Register Now</Button></Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Seven Week Session</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">$11,800</Card.Subtitle>
                            <Card.Text>
                                Friday, June 28th – Wednesday, August 14th.
                            </Card.Text>
                            <Card.Text><Button>Register Now</Button></Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
              
            </Container>
        </main>   
        <AppFooter />   
   </div>
  );
}
import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import AppFooter from '../Components/AppFooter';
import { Button } from 'react-bootstrap';

export default function WorkAtCamp() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan={"Campers and their Parents are at the Heart of Med-O-Lark"} />
        <AppMobNavigation />
        <main className='App-Main'>
            <Container>
                <Row>
                    <h1 className='Page-Name'>Work At Camp</h1>
                    <p>There are lots places where you can spend your summer – why choose Camp Med-O-Lark? The People and the Community!</p>
                    <p>Med-O-Lark is a truly unique place where incredible, creative and caring people come together to help one another achieve his or her very best. Med-O-Lark is that uncommon place where talented and enthusiastic campers work with equally talented and nurturing counselors and other staff to create a summer of fun and incredible memories!</p>
                    <p>Why else? The reasons go on and on, but here are a few ways Med-O-Lark is such a special place to spend your summer:</p>
                </Row>  

                 <Row>
                    <h3>It’ll be a summer you won’t forget!</h3>
                    <p>Camp Med-O-Lark has over fifty different activities to explore and tons of nearby destinations to see and experience. The Med-O-Lark staff is an extremely diverse group. Each summer, staff arrive from all around the United States and the world and bring with them unique perspectives that help to shape the Med-O-Lark community. You’ll learn from fellow staff and develop amazing friendships that will last long after the summer ends.</p>
                </Row>

                <Row>
                    <h3>Med-O-Lark staffers make a difference</h3>
                    <p>As a Med-O-Lark staff member you have an extremely positive impact on campers’ summers. They look up to you, and learn from your example, and leave camp at the end of summer with new skills and accomplishments in large part because of your influence. It’s an incredibly rewarding experience helping campers grow, achieve and excel all the while knowing that you had a large part in it.</p>
                </Row> 

                <Row>
                    <h3>Great Perks</h3>
                    <p>Med-O-Lark offers staff lots of super benefits including: an extremely competitive summer salary; clean, comfortable accommodations; and a robust and insightful staff training program before camp begins. Because we have a high staff to camper ratio, we offer ample time off. On your days off, the spectacular Maine coast beckons as well as a host of other local cultural offerings. Med-O-Lark also maintains a spacious dedicated staff cabin where you’ll find a television and DVD player, free high speed internet access, phones, a kitchen, and plenty of couches for lounging.</p>
                </Row>

                <Row>
                    <h3>Career Training and Experience</h3>
                    <p>Camp Med-O-Lark is a place where staff develops invaluable life skills like leadership, persistence, organization, teamwork, and group management. No matter what field you ultimately go into, a Med-O-Lark summer will always be an asset on your resume. We’re always glad to provide letters of recommendation or reference describing the skills you’ve developed with us.</p>
                    
                </Row> 

                <Row>
                    <p onClick={() => window.location.href= "https://work.medolark.com/"}><Button variant="outline-dark">Apply Now</Button></p>
                </Row> 
            </Container>
        </main>   
        <AppFooter />   
   </div>
  );
}
import React from 'react';
import Button from 'react-bootstrap/Button';

export default function AppMobNavigation() {
  return (
    <nav className='Mobile-Navigation' id="MobileNavigation">
            <ul>
            
                  <li><a href="/about-us">About Med-O-Lark</a></li>
                    <li><a href="/about-us/meet-the-team">Meet the Team</a></li>
                    <li><a href="/about-us/driving-directions">Driving Directions</a></li>
                  
                <li><a href="/2024-camp-session-dates">Dates and Rates</a></li>
                <li><a href="/activities">Activities</a></li>
                <li><a href="/parents-and-carers">Parents & Carers</a></li>

                <li><Button variant="outline-light" onClick={() => window.location.href= "https://parents.medolark.com/"}>Parent Portal</Button></li>
                <li><Button variant="outline-light" onClick={() => window.location.href= "https://registration.medolark.com/"}>Camper Registration</Button></li>
                <li><Button variant="outline-light" onClick={() => window.location.href= "/work-at-camp"}>Work at Camp</Button></li>
            </ul>
        </nav>

  );
}
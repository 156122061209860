import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AppFooter() {

  return (
   <footer className='App-Footer'>
      <Container>
        <Row className='text-center'>
          <Col md={3}>
              <h3>Summer Address</h3>
              <p>Camp Med-O-Lark</p>
              <p>82 Medolark Rd</p>
              <p>Washington</p>
              <p>ME 04574</p>
              <p>Toll Free  | <a href="tel: +1 800-292-7757">800-292-7757</a></p>
              <p>Phone      | <a href="tel: +1 207-845-2555">207-845-2555</a></p>
              <p>Fax        | 207-845-2332</p>
              <p>E-mail     | <a href="mailTo:info@medolark.com">info@medolark.com</a></p>
          </Col>

          <Col md={3}>
              <h3>Off - Season Address</h3>
              <p>10 Prospect St</p>
              <p>Madison</p>
              <p>CT 06443</p>
              <p>Phone  | <a href="tel: +1 203-927-8688">203-927-8688</a></p>
              <p>E-mail | <a href="mailTo:info@medolark.com">info@medolark.com</a></p>
          </Col>

          <Col md={3}>
              <h3>Usefull Links</h3>
              <p><a href="https://business.landsend.com/store/medolark/" target='new'>Camp Gear</a></p>
              <p><a href="/work-at-camp">Work at Camp</a></p>
              <p><a href="/inquirer">Make an Inquiry</a></p>
          </Col>
        </Row>
        
      </Container>
      <Row className='text-center'>
          <ul>
            <li>Copyright &copy; Medolark 2023</li>
            <li>Website Designed and Developed by <a href="https://virexen.com" target="new">Virexen</a></li>
          </ul>
        </Row>
   </footer>
  );
}
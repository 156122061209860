import React from 'react';
import { Route, Routes } from 'react-router-dom'

import "../Styles/App-Main.css"
import "../Styles/App-Responsive.css"
import Home from '../../Routes/Home';
import AboutUs from '../../Routes/AboutUs';
import MeetTheTeam from '../../Routes/MeetTheTeam';
import RatesAndDates from '../../Routes/RatesAndDates';
import Parents from '../../Routes/Parents';
import Activities from '../../Routes/Activities';
import WorkAtCamp from '../../Routes/WorkAtCamp';


export default function App() {
  return (
   <div className="App" id="App" content='App'>
      <Routes>        
         
        <Route exact path="/" element={
           <Home />
        } /> 

      <Route exact path="/about-us" element={
           <AboutUs />
        } /> 

<Route exact path="/about-us/meet-the-team" element={
           <MeetTheTeam />
        } /> 

<Route exact path="/2024-camp-session-dates" element={
           <RatesAndDates />
        } /> 

<Route exact path="/activities" element={
           <Activities />
        } /> 

<Route exact path="/parents-and-carers" element={
           <Parents />
        } /> 

      <Route exact path="/work-at-camp" element={
           <WorkAtCamp />
        } /> 

<Route exact path="*" element={
           <p>Page Not Found! <a href="/">Go Home</a>.</p>
        } /> 
        </Routes>
   </div>
  );
}
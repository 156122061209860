import React from 'react';
import Image from 'react-bootstrap/Image';
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import Button from 'react-bootstrap/Button';

export default function AppHeader({HeaderSlogan}) {

  function ToggleNavigation(){
    var navigation = document.getElementById("MobileNavigation");
    var NavToggle = document.getElementById("NavToggle");
    var NavClose = document.getElementById("NavClose");

    if(navigation.classList.contains("Display-Navigation")){
      navigation.classList.remove("Display-Navigation");
      NavClose.style.display = 'none';
      NavToggle.style.display = 'block';
    }else{
      navigation.classList.add("Display-Navigation");
      NavToggle.style.display = 'none';
      NavClose.style.display = 'block';
    }
  }

  return (
   <header className='App-Header' id="App-Header">
    <div className='App-Header-Overlay'></div>
        <video className='App-Header-Video' playsInline autoPlay loop muted>
            <source src="https://s3.us-east-2.amazonaws.com/medolark.com/videos/mol-header-video.mp4" type="video/mp4"/>
        </video>

        <IoMenuOutline className="App-Mobile-Nav-Toggle" onClick={ToggleNavigation} id="NavToggle"/>
        
        <IoCloseOutline className="App-Mobile-Nav-Toggle" style={{display: "none"}} onClick={ToggleNavigation} id="NavClose"/>

        <nav className='Desktop-Navigation-Nav-Left' id="DesktopNavigation">
            <ul>
                <li><a href="#">About Us</a>
                  <ul>
                    <li><a href="/about-us">About Med-O-Lark</a></li>
                    <li><a href="/about-us/meet-the-team">Meet the Team</a></li>
                    <li><a href="/about-us/driving-directions">Driving Directions</a></li>
                  </ul>
                </li>
                <li><a href="/2024-camp-session-dates">Dates and Rates</a></li>
                <li><a href="#">Activities</a>
                  <ul>
                    <li><a href="/activities/circus-arts">Circus Arts</a></li>
                    <li><a href="/activities/fine-arts">Fine Arts</a></li>
                    <li><a href="/activities/digital-arts">Digital Arts</a></li>
                    <li><a href="/activities/performing-arts">Performing Arts</a></li>
                    <li><a href="/activities/culinary-arts">Culinary Arts</a></li>
                    <li><a href="/activities/dance">Dance</a></li>
                    <li><a href="/activities/sports">Sports</a></li>
                    <li><a href="/activities/waterfront">Waterfront</a></li>
                    <li><a href="/activities/evening-program">Evening Program</a></li>
                  </ul>
                </li>
                <li><a href="/campers">Campers</a></li>
                <li><a href="/parents-and-carers">Parents & Carers</a></li>
                <li><a href="/work-at-camp">Work at Camp</a></li>
            </ul>
        </nav>

        <Image src="https://s3.us-east-2.amazonaws.com/medolark.com/images/medolark-logo.png" className='App-Logo' onClick={() => window.location.href= "/"}/>

        <nav className='Desktop-Navigation' id="DesktopNavigation">
            <ul>
                <li><Button variant="outline-light" onClick={() => window.location.href= "https://parents.medolark.com/"}>Parent Portal</Button></li>
                <li><Button variant="outline-light" onClick={() => window.location.href= "https://staff.medolark.com/"}>Staff Portal</Button></li>
                <li><Button variant="outline-light" onClick={() => window.location.href= "https://registration.medolark.com/"}>Camper Registration</Button></li>
            </ul>
        </nav>

        <div className='App-Header-Info'>
          <h1>{HeaderSlogan}</h1>
        </div>
   </header>
  );
}
import React from 'react';
import AppHeader from '../Components/AppHeader';
import AppMobNavigation from '../Components/AppMobNavigation';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import AppFooter from '../Components/AppFooter';

export default function Home() {
  return (
   <div className='App-Container' id="App-Container" onContextMenu={(e)=> e.preventDefault()}>
        <AppHeader HeaderSlogan={ "Where Campers Make Friendships for a Lifetime" } />
       <AppMobNavigation />
        <section className='App-Section App-Section-info'>
            <Container>
                    <Row>
                        <Col md={""}>
                            <div className='App-Section-Header'><h2>The ARTS Camp of New England.</h2></div>
                            <div className='App-Section-Content'>
                                <p>Founded in 1946, Camp Med-O-Lark is a co-ed, arts summer camp for ages 8–16, nestled on the banks of a pristine lake in Washington, Maine.</p>
                                <p>Med-O-Lark campers create their own schedule from a wide variety of activities — performing arts, fine arts, sports, waterfront fun, and much more.</p>
                                <p>Med-O-Lark is an open, diverse, non-competitive community, where campers make friendships for a lifetime!</p>
                                <Button variant="outline-dark" onClick={() => window.location.href= "/about-us"}>Learn More!</Button>
                            </div>
                        </Col>
                        <Col  md={6}>
                            <Image style={{width: "100%"}} src="/watermarked_4bc34950d8c85c9ea6bd7d604be0df180f082305.jpg" />
                        </Col>
                    </Row>

            </Container>
        </section>

        <section className='App-Section App-Section-Image' style={{backgroundImage: "url('/watermarked_e367e21e9017478114283adde8172874013cbad0.jpg')"}}>
            <div className='App-Section-Header'><h1>JOIN US FOR SUMMER 2024</h1></div>
            <div className='App-Section-Content'>
                <p>We are now open to registrations for summer 2024!</p>
                <p>Want to Make new Friends?</p>
                <p>Learn new Skills?</p>
                <p>Have an Experience of a Lifetime?</p>
                <p>Then Med-O-Lark is the place for you!</p>
            </div>
            <div className='App-Section-Content'>
                <Button variant="outline-light" onClick={() => window.location.href= "/2024-camp-session-dates"}>Learn More!</Button>
            </div>
            <div className='App-Section-Overlay'></div>
        </section>

        <section className='App-Section App-Section-info'>
            <Container>
                    <Row>
                        <Col  md={6}>
                            <Image style={{width: "100%"}} src="/watermarked_4bc34950d8c85c9ea6bd7d604be0df180f082305.jpg" />
                        </Col>
                        <Col md={""}>
                            <div className='App-Section-Header'><h2>Activities at Camp.</h2></div>
                            <div className='App-Section-Content'>
                                <p>Med-O-Lark offers an all-elective program. Campers choose five activities/workshops (from over 50 choices) in each two-week session. In a four-week session, campers can experience up to ten activity/workshop periods.</p>
                                <p>There is a two-day drop-add period to change a class if desired.  Activities are age and gender mixed, allowing each camper the opportunity to befriend many different community members, not just their cabinmates or age group.  </p>
                                <p>No activities at Med-O-Lark are mandatory.  Parents can request specific instruction in some fields and arrangements can be made to accommodate these requests. </p>
                                <Button variant="outline-dark" onClick={() => window.location.href= "/activities"}>Learn More!</Button>
                            </div>
                        </Col>
                    </Row>

            </Container>
        </section>


       <section className='App-Section No-Header'>
   
        <Carousel>
      <Carousel.Item>
        <Image src="/watermarked_39f3f781b1c37b37ffcaeff782a92277ce149f04.jpeg" />
      </Carousel.Item>
    <Carousel.Item>
        <Image src="/watermarked_881cec1c10fc7f551552e607de63512d8feb80d3.jpg" />
      </Carousel.Item>

      <Carousel.Item>
        <Image src="/watermarked_4bc34950d8c85c9ea6bd7d604be0df180f082305.jpg" />
      </Carousel.Item>
    </Carousel>
      
       </section>
<AppFooter />
   
        
   </div>
  );
}